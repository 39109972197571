<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">
          Vat Rates
        </h6>
      </b-row>

    </b-card>
    <div>
      <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">

        <!-- Row Loop -->
        <div v-for="(item, index) in items" :id="item.id" :key="index + '-' + item.id" ref="row" class="pb-2">

          <b-card>
            <b-row>
              <b-col md="8">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Vat Rate" label-for="vat-rate-title">
                      <b-form-input id="vat-rate-title" type="text" v-model="item.title" placeholder="VAT Rate Name" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Percentage" label-for="vat-rate-amount">
                      <b-form-input id="vat-rate-amount" v-model="item.amount" type="number" step="1" min="0.00" max="100.00" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group :label="'Description (Optional)'" label-for="vat-rate-content">
                      <b-form-textarea id="vat-rate-content" type="text" v-model="item.content" placeholder="Vat Rate Description" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <b-form-group :label="'Assigned Products'" label-for="vat-rate-products_tagged" class="producttag">
                  <v-select id="vat-rate-product" v-model="item.products" multiple :options="productsOptions" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="item.id || item.id_temp == 'posted'">
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveOption(index, item.id)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>
              </b-col>
              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" block @click="removeItem(index, item.id)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row v-else>
              <!-- create Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="createOption(index)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Create Vat Rate</span>
                </b-button>
              </b-col>
              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" block @click="removeItemOption(index)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add New</span>
    </b-button>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard, BFormTextarea
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import vSelect from "vue-select";
import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    vSelect
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      vat_rate_raw: [],
      products_raw: [],
      nextTodoId: 1,
      productsOptions: []
    }
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.items = val
        this.$forceUpdate();
      }
    },
  },
  methods: {

    repeateAgain() {
      this.items.push({})
    },
    async createOption(index) {

      await useJwt
        .post_vat('create', {
          title: this.items[index].title,
          content: this.items[index].content,
          amount: this.items[index].amount
        })
        .then(async () => {

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vat Rate created`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully created vat_rate.`,
            },
          });


          await useJwt
            .get_vat()
            .then((response) => {
              localStorage.setItem(
                "userVatRate",
                JSON.stringify(response.data.data)
              );
              this.vat_rate_raw = response.data.data
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't downlad your vat rates data from the server.`,
                },
              });
            });

          if (this.vat_rate_raw.length) {
            this.items = []
            let item = {};
            for (let x = 0; x < this.vat_rate_raw.length; x++) {
              item.id = this.vat_rate_raw[x].id;
              item.title = this.vat_rate_raw[x].title;
              item.amount = this.vat_rate_raw[x].amount;
              item.content = this.vat_rate_raw[x].content;
              item.products = [];
              this.items.push(item);
              item = {};
            }
          }

        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new vat rates on the server.`,
            },
          });
        });
    },
    async saveOption(index, id) {

      await useJwt
        .patch_vat(id, {
          title: this.items[index].title,
          content: this.items[index].content,
          amount: this.items[index].amount
        })
        .then(async (response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vat rate saved`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully saved vat rate.`,
            },
          });

        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new vat rate on the server.`,
            },
          });
        });
      if (this.items[index].products) {
        for (let x = 0; x < this.items[index].products.length; x++) {
          await useJwt
            .patch_product(this.items[index].products[x].value, {
              vat_id: id,
            })
            .then(() => {
              this.$forceUpdate();
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't tag your product data on the server.`,
                },
              });
            });
        }
      }
    },
    removeItemOption(index) {
      this.items.splice(index, 1)
    },
    async removeItem(index, id) {
      await useJwt
        .delete_vat(id)
        .then(async (response) => {
          this.items.splice(index, 1)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vat rate removed`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully removed vat rate.`,
            },
          });

        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new vat rate on the server.`,
            },
          });
        });
    }
  },
  async created() {
    await useJwt
      .list_products()
      .then((response) => {
        localStorage.setItem(
          "userProducts",
          JSON.stringify(response.data.data)
        );
        this.products_raw = response.data.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .get_vat(this.id)
      .then((response) => {
        localStorage.setItem(
          "userVatRate",
          JSON.stringify(response.data.data)
        );
        this.vat_rate_raw = response.data.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your attributes data from the server.`,
          },
        });
      });

    if (this.vat_rate_raw.length) {
      let item = {};
      for (let x = 0; x < this.vat_rate_raw.length; x++) {
        item.id = this.vat_rate_raw[x].id;
        item.title = this.vat_rate_raw[x].title;
        item.amount = this.vat_rate_raw[x].amount;
        item.content = this.vat_rate_raw[x].content;
        item.products = []
        for (let y = 0; y < this.products_raw.length; y++) {
          if (this.products_raw[y].vat_id == this.vat_rate_raw[x].id) {
            item.products.push({
              value: this.products_raw[y].id,
              label: '(#' + this.products_raw[y].id + ') ' + this.products_raw[y].title
            });
            this.products_raw[y] = {}
          }
        }

        this.items.push(item);
        item = {};
      }
      for (let x = 0; x < this.products_raw.length; x++) {
        if (this.products_raw[x].id) {
          this.productsOptions.push({
            value: this.products_raw[x].id,
            label: '(#' + this.products_raw[x].id + ') ' + this.products_raw[x].title
          });
        }
      }
    }
    for (let x = 0; x < this.products_raw.length; x++) {
        if (this.products_raw[x].id) {
          this.productsOptions.push({
            value: this.products_raw[x].id,
            label: '(#' + this.products_raw[x].id + ') ' + this.products_raw[x].title
          });
        }
      }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
